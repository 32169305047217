
import {defineComponent, ref, watch} from 'vue'
import PublicFormService from "@/core/services/PublicFormService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import PublicFormError from "@/views/public-form/PublicFormError.vue";
import {DOCUMENT_TYPES, DOCUMENT_TYPES_FR} from "@/core/config/Constant";
import DocumentRequestForm from "@/views/public-form/form/DocumentRequestForm.vue";

export default defineComponent({
  name: "PublicForm",
  components: {DocumentRequestForm, PublicFormError, EntityLoading},
  props: {
    query: {required: true},
  },
  setup(props) {
    const loading = ref(true);
    const model = ref<any>({})
    const submitting = ref(false);
    const submitted = ref(false);
    const buildForm = (data) => {
      loading.value = true;
      PublicFormService.buildForm(data).then(res => {
        model.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }

    buildForm(props.query)
    watch(() => props.query, cb => {
      buildForm(cb)
    })
    return {
      submitting,
      loading,
      model,
      submitted,
      DOCUMENT_TYPES,
      DOCUMENT_TYPES_FR,
    }

  },
  methods: {

  }
})
