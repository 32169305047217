import {BaseService} from "@/core/services/BaseService";

class PublicFormService extends BaseService<any> {
  endpoint = "/public-form"

  async buildForm(data) {
    return this.http().post(this.endpoint, data).then(res => {
      return res.data
    })
  }

  async submit(data) {
    return this.uploadHttp().post(this.endpoint + "/documents-request", data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      return res.data
    })
  }
}

export default new PublicFormService();