
import {defineComponent, ref} from 'vue'
import {useToast} from "vue-toast-notification";
import {buildFormData} from "@/core/helpers/functions";
import PublicFormService from "@/core/services/PublicFormService";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {DOCUMENT_TYPES, DOCUMENT_TYPES_EN, DOCUMENT_TYPES_FR, DOCUMENT_TYPES_FR_FULL} from "@/core/config/Constant";
import InfoBox from "@/components/base/InfoBox.vue";

export default defineComponent({
  name: "DocumentRequestForm",
  components: {InfoBox, BaseSelect},
  props: {
    form: {},
    query: {required: true, type: Object},
    lang: {type: String, required: true, default: "EN"},
  },
  setup(props) {
    const model = ref<any>(props.form);
    const submitting = ref(false);
    const supportedFileTypes = 'PDF, DOC, DOCX, JPG, JPEG, PNG';

    const translations = {
      EN: {
        title: "Upload Claim Documents",
        description: "Claim",
        requiredDocs: "Please upload all required documents. Supported formats: PDF, JPG, PNG (max 10MB per file)",
        uploadNote: "Include all necessary documents to ensure fast claim processing",
        addMore: "Add More Documents",
        submit: "Submit Documents",
        remove: "Remove",
        upload: "Upload",
        change: "Change",
        supportedTypes: "Supported file types",
        wait: "Please wait...",
      },
      FR: {
        title: "Télécharger les documents de réclamation",
        description: "Réclamation",
        requiredDocs: "Veuillez télécharger tous les documents requis. Formats pris en charge : PDF, JPG, PNG (max. 10 Mo par fichier)",
        uploadNote: "Incluez tous les documents nécessaires pour assurer un traitement rapide de la réclamation",
        addMore: "Ajouter d'autres documents",
        submit: "Soumettre les documents",
        remove: "Supprimer",
        upload: "Téléverser",
        change: "Modifier",
        supportedTypes: "Types de fichiers pris en charge",
        wait: "Veuillez patienter...",
      }
    };
    return {
      model,
      translations,
      supportedFileTypes,
      submitting,
      DOCUMENT_TYPES_FR,
      DOCUMENT_TYPES_EN,
      DOCUMENT_TYPES_FR_FULL,
      DOCUMENT_TYPES,
    }
  },
  methods: {
    addLine() {
      this.model.form.files.push({id: '', name: '', type: 'CLAIM_FORM', file: ''})
    },
    removeLine(line) {
      this.model.form.files = this.model.form.files.filter(item => item !== line)
    },

    onFileSelected(file, line) {
      line.file = file
      line.name = file.name
    },
    handleFileUpload(doc, event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files[0]) {
        const file = input.files[0];
        doc.file = file;
        doc.name = file.name;
        doc.status = 'uploaded';
      }
    },

    onSubmit() {

      const hasNull = this.model.form.files.every(item => item.file === "");
      if (hasNull) {
        useToast().warning('Please upload document file', {position: 'top-right'})
        return
      }
      const finalDocs = this.model.form.files.filter(item => item.file !== "");
      const files = finalDocs.map(item => item.file);
      const docs = finalDocs.map(item => Object.assign({id: item.id, name: item.name, type: item.type}));
      this.submitting = true;
      const formData = buildFormData(this.query);
      formData.set("docs", JSON.stringify(docs))
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      PublicFormService.submit(formData).then(() => {
        this.$router.push({name: 'publicFormSuccess', query: {f: this?.model.form.type, l: this.query.l}});
      }).finally(() => {
        this.submitting = false;
      })
    },
    toggleLanguage(){
      if (this.lang === "EN") {
        this.$router.push({name: 'publicForm', query: {p: this.query.p, l: "FR"}});
      }else {
        this.$router.push({name: 'publicForm', query: {p: this.query.p, l: "EN"}});
      }
    }
  }

})
